import { displayDateOnly, displayTime } from '@/helpers/ag-grid/cellFormats'

import { timeHeader } from '@/helpers/ag-grid/headerFormats'

export default [
    {
        headerName: 'Batch ID',
        field: 'batchId',
        hide: true,
    },
    {
        headerName: 'Date',
        field: 'startTime', //using startTime because the DB value for date doesn't have a timezone
        width: 140,
        valueFormatter: (params) => displayDateOnly(params.value),
    },
    {
        headerName: timeHeader('Start Time'),
        field: 'startTime',
        valueFormatter: (params) => displayTime(params.value),
        width: 175,
    },
    {
        headerName: timeHeader('End Time'),
        field: 'endTime',
        valueFormatter: (params) => displayTime(params.value),
        width: 175,
    },
    {
        headerName: 'Errors',
        cellRendererFramework: 'RowButton',
        cellRendererParams: {
            buttonParams: {
                routerLinkIds: ['batchId'],
                routeName: 'export-log-results',
                color: 'error',
                query: { agFilters: { errors: 0 } },
            },
        },
        filter: 'agNumberColumnFilter',
        filterParams: {
            filterOptions: ['greaterThan'],
            defaultOption: 'greaterThan',
        },
        field: 'errors',
        width: 150,
    },
    {
        headerName: 'Warnings',
        cellRendererFramework: 'RowButton',
        cellRendererParams: {
            buttonParams: {
                routerLinkIds: ['batchId'],
                routeName: 'export-log-results',
                color: 'warning',
                query: { agFilters: { warnings: 0 } },
            },
        },
        filter: 'agNumberColumnFilter',
        filterParams: {
            filterOptions: ['greaterThan'],
            defaultOption: 'greaterThan',
        },
        field: 'warnings',
        width: 150,
    },
    {
        headerName: 'Records Processed',
        field: 'recordsProcessed',
        cellRendererFramework: 'ChipCellNumber',
        cellRendererParams: {
            color: 'primary',
        },
    },
    {
        headerName: 'Total Files',
        field: 'filesProcessed',
        cellRendererFramework: 'ChipCellNumber',
        cellRendererParams: {
            color: 'primary',
        },
    },
    {
        headerName: 'Action',
        cellRendererFramework: 'RowButton',
        cellRendererParams: {
            buttonParams: {
                routerLinkIds: ['batchId'],
                routeName: 'export-log-results',
                btnText: 'Results',
                color: '',
            },
        },
        filter: false,
        width: 140,
        pinned: 'right',
        lockPinned: true,
        lockVisible: true,
        lockPosition: true,
        hide: false,
        suppressMenu: true,
        sortable: false,
    },
]
