<template>
    <child-layout title="Export Logs">
        <ag-grid-server-side
            id="exportLogHeaders"
            :server-side-datasource="serverSideDatasource"
            server-side-store-type="full"
            suppress-row-click-selection="true"
            :column-defs="columnDefs"
        />
    </child-layout>
</template>

<script>
import ChildLayout from '@/components/layout/ChildLayout'
import AgGridServerSide from '@/components/table/AgGridServerSide'
import ColumnDef from '@/helpers/ag-grid/columnDef/exportLog/headers'
import { mapActions, mapGetters } from 'vuex'

export default {
    name: 'ExportLogHeaders',
    components: {
        ChildLayout,
        AgGridServerSide,
    },
    data: () => ({
        columnDefs: ColumnDef,
    }),
    computed: {
        ...mapGetters({
            fetching: 'exportLogHeaders/getFetching',
            items: 'exportLogHeaders/getItems',
            lea: 'getDistrictLea',
        }),
    },
    methods: {
        ...mapActions({
            fetchRows: 'exportLogHeaders/get',
        }),
        serverSideDatasource: function () {
            const fetch = async () => {
                const leaId = this.lea
                await this.fetchRows({ lea: leaId })

                return this.items
            }

            return {
                getRows: async function (params) {
                    const data = await fetch()

                    params.successCallback(data, data.length)
                },
            }
        },
    },
}
</script>

<style scoped>

</style>